<template>
    <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
        <v-alert class="alert" v-model="alert" dismissible color="error" icon="mdi-alert-circle"
            transition="slide-x-reverse-transition" dark>
            {{ alertaTxt }}
        </v-alert>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col class="text-center">
                    <v-card class="mx-auto" max-width="500" style="border-radius: 10px">
                        <v-img class="white--text align-end" height="125px" :src="customization.logo"
                            style="background-color: transparent; border-radius: 5px" />
                        <v-card-subtitle>Informe seus dados para recuperar sua senha!</v-card-subtitle>
                        <v-row align="center" justify="center">
                            <v-col cols="10" md="4">
                                <v-text-field label="Conta" :rules="fieldRules" v-model="inputConta" id="conta_field"
                                    @keydown.enter="esqueciSenha" outlined></v-text-field>
                            </v-col>
                            <v-col cols="10" md="6" class="forms_input_mobile_index">
                                <v-text-field label="Usuário" :rules="fieldRules" v-model="inputUsuario" id="usuario_field"
                                    @keydown.enter="esqueciSenha" outlined></v-text-field>
                            </v-col>
                            <v-col cols="10" class="forms_input_mobile_index2">
                                <v-text-field label="Email" type="email" :rules="fieldRules" v-model="inputEmail"
                                    id="email_field" @keydown.enter="esqueciSenha" outlined></v-text-field>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn text to="/" color="secondary"><small>Voltar para login</small></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text @click="esqueciSenha" color="primary">
                                Recuperar senha
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div style="position: fixed; bottom: 15px; text-align: center; width: 100%; opacity: .5">
                Versão {{ version }}
            </div>
        </v-container>
    </div>
</template>

<style scoped>
.alert {
    position: fixed;
    right: 5px;
    bottom: 5px;
    max-width: 500px;
    width: calc(100% - 10px);
    z-index: 1000000;
}

.forms_input_mobile_index {
    transform: translateY(0px);
}

.forms_input_mobile_index2 {
    transform: translateY(-30px);
}

@media screen and (max-width: 960px) {
    .forms_input_mobile_index {
        transform: translateY(-25px)
    }

    .forms_input_mobile_index2 {
        transform: translateY(-55px);
    }
}

.home {
    height: 100%;
    background-color: #28396b;
}
</style>

<script>
import api from '@/plugins/api.js'
import router from '@/router/index.js'

export default {
    name: 'RecuperarSenha',
    data: () => ({
        fieldRules: [
            r => (r && r.length > 0) || "Preencha esse campo."
        ],
        inputConta: null,
        inputUsuario: null,
        inputEmail: null,
        alert: false,
        inputLembrar: false,
        version: null,
        customization: {},
        alertaTxt: null
    }),
    methods: {
        esqueciSenha() {
            if (!this.inputConta) return document.getElementById("conta_field").focus();
            if (!this.inputUsuario) return document.getElementById("usuario_field").focus();
            if (!this.inputEmail) return document.getElementById("email_field").focus();

            api()
                .get(`endpoint/noauth/recuperarSenha/addwhere=email:${this.inputEmail},login:${this.inputUsuario},account:${this.inputConta}`)
                .then(r => {
                    console.log(r.data);
                    if (r.ok) {
                        this.alert = true;
                        this.alertaTxt = (r.data.endpoint[0][0].error) ? (r.data.endpoint[0][0].error) : "Verifique seu email para recuperar sua conta!";
                    }
                })
        },
    },
    mounted() {
        this.customization = window.mainApp.customization;
        this.version = window.mainApp.version;

        if (localStorage.getItem("usrId")) router.push('map');
    }
}
</script>

