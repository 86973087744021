<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar />

    <!-- botões para acessar outras página  -->
    <v-speed-dial v-model="fabMaisOpcoes" :direction="top" transition="slide-x-reverse-transition"
      style="margin-bottom: 10px;" bottom right absolute fixed>
      <template v-slot:activator>
        <v-btn v-model="fabMaisOpcoes" color="black" elevation="6" fab dark>
          <v-icon v-if="fabMaisOpcoes">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="red" router to="/Map">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn fab dark small color="purple" router to="/Vehicle">
        <v-icon>mdi-car</v-icon>
      </v-btn>
      <!-- <v-btn fab dark small color="green" router to="/Events">
        <v-icon>mdi-calendar-text</v-icon>
      </v-btn> -->
      <v-btn fab dark small color="indigo" router to="/Report">
        <v-icon>mdi-text-box-outline</v-icon>
      </v-btn>
    </v-speed-dial>

    <div id="div-content">
      <v-banner id="divTitle" class="text-h6" shaped>
        Dados da conta<br />
      </v-banner>

      <div class="divList">
        <v-row no-gutters>
          <v-col></v-col>
          <v-col cols="6" align-self="center">
            <v-list class="list" elevation="4" style="padding: 10px" dense rounded>
              <v-subheader>Informações Básicas</v-subheader>
              <v-list-item>
                <v-text-field readonly label="Nome" v-model="info.nome" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="CNPJ/CPF" v-model="info.cnpj" />
              </v-list-item>
              <!-- <v-list-item>
                <v-text-field readonly label="Categoria" v-model="info.ccategoria" />
              </v-list-item> -->
              <!-- <v-list-item>
                <v-text-field readonly label="Plano" v-model="info.descricao_plano" />
              </v-list-item> -->
              <!-- <v-list-item>
                <v-text-field readonly label="Parceria" v-model="info.parceria" />
              </v-list-item> -->
              <!-- <v-list-item>
                <v-text-field readonly label="Website" v-model="info.website" />
              </v-list-item> -->
              <!-- <v-list-item>
                <v-text-field readonly label="Tempo Médio" v-model="info.tempomedio" />
              </v-list-item> -->
              <v-divider></v-divider>
              <v-subheader>Endereço</v-subheader>
              <v-list-item>
                <v-text-field readonly label="Endereço" v-model="info.endereco" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="Número" v-model="info.numero" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="Complemento" v-model="info.complemento" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="Bairro" v-model="info.bairro" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="Cidade" v-model="info.cidade" />
              </v-list-item>
              <v-list-item>
                <v-text-field readonly label="Estado" v-model="info.estado" />
              </v-list-item>
            </v-list>
          </v-col>
          <v-col></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style scoped>
.divList {
  height: calc(100vh);
  overflow-y: auto;
  /* box-shadow: rgba(255, 255, 255, 0.5) 0px 10px 20px -6px inset; */
}
</style>

<script>
import Navbar from '../components/Navbar';
import api from '../plugins/api';

export default {
  name: 'myAccount',
  components: {
    Navbar
  },
  data: () => ({
    user: {},
    info: {},
    customization: {},
  }),
  methods: {

  },
  mounted() {
    this.customization = window.mainApp.customization;
    if (localStorage.getItem("user")) this.user = JSON.parse(localStorage.getItem("user"));
    api(localStorage.getItem("token"))
      .get(`endpoint/minhaConta/addwhere=idcliente:${this.user.IDCLIENTE}?noPagination=true`) ////this.user.IDCLIENTE}
      .then(r => {
        if (r.ok)
          this.info = r.data.endpoint[0];
      })
    setTimeout(() => {
      window.navbar.setTitle("Minha Conta");
    }, 100);
  }
}
</script>