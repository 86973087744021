<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar />

    <!-- botões para acessar outras página  -->
    <v-speed-dial v-model="fabMaisOpcoes" :direction="top" transition="slide-x-reverse-transition"
      style="margin-bottom: 10px;" bottom right absolute fixed>
      <template v-slot:activator>
        <v-btn v-model="fabMaisOpcoes" color="black" elevation="6" fab dark>
          <v-icon v-if="fabMaisOpcoes">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="red" router to="/Map">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn fab dark small color="purple" router to="/Vehicle">
        <v-icon>mdi-car</v-icon>
      </v-btn>
      <!-- <v-btn fab dark small color="green" router to="/Events">
          <v-icon>mdi-calendar-text</v-icon>
        </v-btn> -->
      <v-btn fab dark small color="indigo" router to="/Report">
        <v-icon>mdi-text-box-outline</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-alert style="position: fixed; width:80vw; top: 70vh; z-index: 200; right: 0px;" v-model="mapType_changed_atc"
      :type="alert_type" transition="scroll-x-reverse-transition" elevation="6">
      {{ msg_alert }}
    </v-alert>

    <div id="div-content">
      <v-banner id="divTitle" class="text-h6" shaped>
        Lista de Eventos<br />
      </v-banner>

      <div v-if="items.length == 0" style="width: 100%; text-align: center;margin-top: 200px;">
        <span>Não há Eventos</span><br>
      </div>

      <div id="divList">
        <v-list class="list" dense v-if="items.length > 0">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
              <v-icon x-large textContent="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import api from '../plugins/api';

export default {
  name: 'Events',
  components: {
    Navbar
  },
  data: () => ({
    items: [],
    tabs: null,
    user: {},
    fabMaisOpcoes: false,
    mapType_changed_atc: false,
    msg_alert: "",
    alert_type: "success",
    timer_update_map: null,
    customization: {},
  }),
  methods: {
    showAlert(msg, type) {
      this.msg_alert = msg;
      this.alert_type = type;
      this.mapType_changed_atc = true;
      setTimeout(() => {
        this.mapType_changed_atc = false;
      }, 5000);
    },
  },
  mounted() {
    this.customization = window.mainApp.customization;

    if (localStorage.getItem("user")) this.user = JSON.parse(localStorage.getItem("user"));

    setTimeout(() => {
      window.navbar.setTitle("Eventos");
    }, 100);

    window.listEvents = (filtros) => {
      window.navbar.loading = true;
      this.items = [];

      let parPlaca = "";
      if (filtros.veiculo && filtros.veiculo.placa)
        parPlaca = `,placas:${filtros.veiculo.placa}`;

      const idCliente = `idcliente:${this.user.IDCLIENTE}`;
      const dataIni = `,datainicial:${filtros.datainicial.split("T")[0]}`;
      const horaIni = `,horainicial:${filtros.datainicial.split("T")[1]}`;
      const dataFim = `,datafinal:${filtros.datafinal.split("T")[0]}`;
      const horaFim = `,horafinal:${filtros.datafinal.split("T")[1]}`;

      api(localStorage.getItem("token"))
        .get(`endpoint/eventos/addwhere=${idCliente}${parPlaca}${dataIni}${dataFim}${horaIni}${horaFim}?noPagination=true`)
        .then(r => {
          if (r.ok) {
            try {
              if (r.data.endpoint[0].length > 0) {
                r.data.endpoint[0].forEach(e => {
                  this.items.push(
                    {
                      icon: "mdi-calendar-text",
                      title: e.placa,
                      subtitle: e.evento + " - " + e.localizacao
                    })
                });
              } else {
                this.showAlert("Não encontramos dados para os filtros informados", "warning");
                setTimeout(() => {
                  window.navbar.dlgEvents = true;
                }, 5000);
              }
            } catch (e) {
              this.showAlert(`Não encontramos dados para os filtros informados\n${e.message}`, "warning");
              setTimeout(() => {
                window.navbar.dlgEvents = true;
              }, 5000);
            }
          }

          window.navbar.loading = false;
        });

    }

    // abre o dialogo de filtro assim que entra na tela do relatório
    window.navbar.dlgEvents = true;
  }
}
</script>

