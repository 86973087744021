<template>
    <div>
        <v-navigation-drawer v-model="drawer" app clipped temporary :dark="true" color="primary">
            <v-img height="100" :src="customization.logo"></v-img>
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item style="padding-left: 0px;">
                        <v-list-item-content>
                            <v-list-item-title class="title">Conta: {{ usuario.account }}</v-list-item-title>
                            <v-list-item-subtitle>Usuário: {{ usuario.login }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item dense style="padding-top: 5px; padding-bottom: 5px">
                    <v-list-item-title><b>Versão do Aplicativo:</b> {{ version }}</v-list-item-title>
                </v-list-item>
                <v-list-item link router @click="logout">
                    <v-list-item-title>Sair</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <v-list dense>
                <!-- link router :to="sitem.link" -->
                <v-list-item v-for="(sitem, i) in singleItems" :key="i" @click="navigateTo(sitem.link)">
                    <v-list-item-title>{{ sitem.text }}</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>{{ sitem.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-group v-for="(item, i) in items" :key="item.text + '_' + i" :prepend-icon="item.icon">
                    <template v-slot:activator>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </template>
                    <v-list-item v-for="(sitem, i) in item.items" :key="i" @click="navigateTo(sitem.link)">
                        <v-list-item-title>{{ sitem.text }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>{{ sitem.icon }}</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar :dark="true" color="primary" class="app-bar" app clipped-left absolute fixed>
            <v-app-bar-nav-icon @click="drawer = !drawer" style="color: #f2f2f2" v-if="['Map'].includes(getActual())">
                <v-icon>{{ drawer ? "mdi-backburger" : "mdi-menu" }}</v-icon> <!-- mdi-menu-open -->
            </v-app-bar-nav-icon>

            <v-app-bar-nav-icon style="color: #f2f2f2" @click="goBack"
                v-if="['Report', 'Vehicles', 'Events', 'Config', 'myAccount'].includes(getActual())">
                <v-icon>mdi-arrow-left</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-tooltip v-if="['Report'].includes(getActual())" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                        <v-icon v-bind="attrs" color="#ffffff" v-on="on" @click="dialog = true">mdi-magnify</v-icon>
                    </v-btn>
                </template>
                <span>Filtrar</span>
            </v-tooltip>

            <!-- <v-tooltip v-if="['Events'].includes(getActual())" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                        <v-icon v-bind="attrs" color="#ffffff" v-on="on" @click="dlgEvents = true">mdi-magnify</v-icon>
                    </v-btn>
                </template>
                <span>Filtrar</span>
            </v-tooltip> -->

            <v-tooltip v-if="['Map'].includes(getActual()) && !viewBtnMenuFrota" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" router to="/Vehicle">
                        <v-icon color="#ffffff">mdi-car</v-icon>
                    </v-btn>
                </template>
                <span>Veículos</span>
            </v-tooltip>

            <v-tooltip v-if="['Map'].includes(getActual()) && viewBtnMenuFrota" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="loadFrotaMapa">
                        <v-icon>mdi-home</v-icon>
                    </v-btn>
                </template>
                <span>Mapa Frota</span>
            </v-tooltip>

            <!-- <v-tooltip v-if="['Map'].includes(getActual())" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                        <v-icon v-bind="attrs" color="#ffffff" v-on="on">mdi-headphones</v-icon>
                    </v-btn>
                </template>
                <span>Ouvidoria</span>
            </v-tooltip> -->
            <!-- <v-tooltip v-if="['Map', 'Report', 'Vehicles', 'Events'].includes(getActual())" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon>
                        <v-icon v-bind="attrs" color="#ffffff" v-on="on" @click="reload">mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Recarregar</span>
            </v-tooltip> -->
        </v-app-bar>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-progress-circular :size="50" color="primary" indeterminate v-if="loading"
            class="progress-loading"></v-progress-circular>

        <!-- Filtro relatórios -->
        <v-dialog v-model="dialog" width="auto" persistent>
            <v-card>
                <div style="float: right; transform: translate(-13px,13px)">
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-title class="headline">
                    Relatório
                </v-card-title>
                <v-card-subtitle>
                    Selecione os dados abaixo para gerar o relatório
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select width="100%" label="Tipo de relatório" v-model="filtros.type" :items="types" return-object
                                item-text="text" item-value="value" hide-details="hidden">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select width="100%" v-model="filtros.veiculo" :items="entries" label="Veículo" item-value="idveiculo"
                                item-text="placa" return-object hide-details="hidden"></v-select>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-autocomplete v-model="filtros.veiculo" label="Veiculo" return-object :error="error != ''"
                                :error-messages="error" hide-details="auto" item-value="idveiculo" item-text="placa"
                                :loading="isLoading" :search-input.sync="search" :items="entries"></v-autocomplete>
                        </v-col> -->
                        <v-col cols="12">
                            <v-text-field width="100%" hide-details="hidden" label="Data Inicial" type="datetime-local"
                                v-model="filtros.datainicial" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field hide-details="hidden" label="Data Final" type="datetime-local"
                                v-model="filtros.datafinal" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-if="filtros.type.value == 'reportExcessoVelocidade'" hide-details="hidden"
                                label="Velocidade" type="number" v-model="filtros.velocidade" />
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="filtrar" width="100%" color="primary">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo filtros eventos -->
        <v-dialog v-model="dlgEvents" width="auto" persistent>
            <v-card>
                <div style="float: right; transform: translate(-13px,13px)">
                    <v-btn icon @click="dlgEvents = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-title class="headline">
                    Filtros
                </v-card-title>
                <v-card-subtitle>
                    Selecione os filtros para listar os eventos
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select v-model="filtrosEvents.veiculo" :items="entries" label="Veiculo"
                                item-value="idveiculo" item-text="placa" return-object hide-details="hidden"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field hide-details="hidden" label="Data Inicial" type="datetime-local"
                                v-model="filtrosEvents.datainicial" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field hide-details="hidden" label="Data Final" type="datetime-local"
                                v-model="filtrosEvents.datafinal" />
                        </v-col>
                        <v-col cols="12">
                            <v-btn @click="filtrarEvents" width="100%" color="primary">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
.app-bar {
    margin: 70px 15px !important;
    border-radius: 10px !important;
}

.progress-loading {
    position: absolute;
    top: calc(50vh - 25px);
    left: calc(50vw -25px);
    z-index: 10;
}
</style>

<script>
import api from '@/plugins/api.js';
// import router from '@/router/index.js'

export default {
    name: 'Navbar',
    data: () => ({
        drawer: false, // window.innerWidth > 900,
        items: [],
        version: "",
        singleItems: [],
        usuario: {},
        customization: {},
        router: {},
        error: "",
        title: "",
        filtros: {
            type: {
                text: "Posições",
                value: "reportPosicoes"
            },
            velocidade: 120
        },
        filtrosEvents: {},
        dialog: false,
        dlgEvents: false,
        loading: false,
        search: null,
        isLoading: false,
        count: null,
        entries: [],
        types: [
            {
                text: "Posições",
                value: "reportPosicoes"
            },
            {
                text: "Status Ignição",
                value: "reportStatusIgnicao"
            },
            {
                text: "Excesso de Velocidade",
                value: "reportExcessoVelocidade"
            }
        ],
        viewBtnMenuFrota: false,
    }),
    methods: {
        goBack() {
            localStorage.setItem("navigatedFromVehicle", 0);
            this.$router.go(-1);
        },
        navigateTo(path) {
            localStorage.setItem("navigatedFromVehicle", 0);
            this.$router.push(path);
        },
        setTitle(s) {
            this.title = s;
        },
        logout() {
            window.mainApp.logout();
        },
        getActual() {
            return this.$route.name;
        },
        reload() {
            this.$router.go()
        },
        filtrar() {
            if (!this.filtros.veiculo) return this.error = "Você precisa selecionar um veiculo"
            if (this.filtros.velocidade == "") this.filtros.velocidade = 120
            window.generateReport(this.filtros)
            this.dialog = false;
        },
        filtrarEvents() {
            window.listEvents(this.filtrosEvents);
            this.dlgEvents = false;
        },
        loadFrotaMapa() {
            window.loadFrotaMapa();
        },
        getItems() {
            api(localStorage.getItem("token"))
                .get(`endpoint/getMenu/addwhere=idusuario:${localStorage.getItem("usrId")}`)
                .then(r => {
                    if (r.ok) {
                        let items = [];
                        const menu = Object.values(r.data.endpoint[0]);

                        menu.forEach(i => {
                            const parent = items.map(it => it.id).indexOf(i.parentID);

                            if (parent >= 0) {

                                items[parent].items.push({
                                    icon: `mdi-${i.IconeVue}`,
                                    text: `${i.caption}`,
                                    link: `/${i.formVue}`
                                });
                            }
                            else {
                                if (i.caption.toLowerCase() != "eventos")
                                    items.push({
                                        icon: `mdi-${i.IconeVue}`,
                                        text: `${i.caption}`,
                                        link: `/${i.formVue}`,
                                        items: [],
                                        id: i.idacao
                                    });
                            }
                        });
                        items = items.sort((a, b) => { return a.items.length - b.items.length });
                        items.push({
                            icon: `mdi-account`,
                            text: `Minha Conta`,
                            link: `/myAccount`
                        });
                        /* items.push({
                            icon: `mdi-cog`,
                            text: `Configurações`,
                            link: `/Config`
                        }); */

                        this.singleItems = items;
                    }
                })
        },
    },
    /* watch: {
        search() {
            if (!this.search || this.search.length == 0) this.model = null;

            if (this.isLoading) return

            this.isLoading = true;

            api(localStorage.getItem("token"))
                .get(`endpoint/getVeiculo/addwhere=idcliente:${JSON.parse(localStorage.user).IDCLIENTE}?search=${this.search}&noPagination=true`)
                .then(res => {
                    this.count = res.data.endpoint.length;
                    this.entries = res.data.endpoint;
                })
                .catch(() => {
                })
                .finally(() => (this.isLoading = false))
        }
    }, */
    mounted() {
        if (localStorage.getItem("user")) this.usuario = JSON.parse(localStorage.getItem("user"));
        if (this.usuario.Nome.length > 18) this.usuario.Nome = this.usuario.Nome.substring(0, 16) + "...";
        if (localStorage.getItem("viewBtnMenuFrota")) this.viewBtnMenuFrota = localStorage.getItem("viewBtnMenuFrota") == "true";
        this.usuario.loginDisplay = (this.usuario.login.length > 30) ? this.usuario.login.substring(0, 27) + "..." : this.usuario.login;
        this.version = window.mainApp.version;
        this.customization = JSON.parse(localStorage.getItem("customization"));

        this.getItems();

        const nLeft = (n) => {
            if (n < 10)
                n = "0" + n
            return n;
        }

        const datafinal = new Date();
        const datainicial = new Date();

        /* carrega os dados iniciais para o dialogo de filtro de eventos */
        datainicial.setDate(datainicial.getDate() - 7);
        this.filtros = {
            datainicial: `${datainicial.getFullYear()}-${nLeft(datainicial.getMonth() + 1)}-${nLeft(datainicial.getDate())}T${nLeft(datainicial.getHours())}:${nLeft(datainicial.getMinutes())}:${nLeft(datainicial.getSeconds())}`,
            datafinal: `${datafinal.getFullYear()}-${nLeft(datafinal.getMonth() + 1)}-${nLeft(datafinal.getDate())}T${nLeft(datafinal.getHours())}:${nLeft(datafinal.getMinutes())}:${nLeft(datafinal.getSeconds())}`,
            type: {
                text: "Posições",
                value: "reportPosicoes"
            },
            velocidade: 120
        }

        /* carrega as datas inicial e final para o dialogo de filtro de eventos */
        this.filtrosEvents = {
            datainicial: `${datainicial.getFullYear()}-${nLeft(datainicial.getMonth() + 1)}-${nLeft(datainicial.getDate())}T${nLeft(datainicial.getHours())}:${nLeft(datainicial.getMinutes())}:${nLeft(datainicial.getSeconds())}`,
            datafinal: `${datafinal.getFullYear()}-${nLeft(datafinal.getMonth() + 1)}-${nLeft(datafinal.getDate())}T${nLeft(datafinal.getHours())}:${nLeft(datafinal.getMinutes())}:${nLeft(datafinal.getSeconds())}`
        }

        /* carrega a lista de veículos para os dialogos de filtros */
        api(localStorage.getItem("token"))
            .get(`endpoint/getVeiculo/addwhere=idcliente:${JSON.parse(localStorage.user).IDCLIENTE}?noPagination=true`)
            .then(res => {
                this.entries = res.data.endpoint;
            });

        window.navbar = this;
    }
}
</script>

