<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar />

    <div id="div-content">
      <v-banner id="divTitle" class="text-h6" shaped>
        Configurações do sistema <br />
      </v-banner>

      <div id="divList">
        <v-list class="list" elevation="4" dense>
          <!-- <v-subheader>Notificações</v-subheader>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Notificações de Eventos</v-list-item-title>
                        <v-list-item-subtitle>Receber notificações (via push) nos últimos eventos.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Som</v-list-item-title>
                      <v-list-item-subtitle>Som de notificação (Padrão)</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Som do antifurto</v-list-item-title>
                        <v-list-item-subtitle>Som de notificação antifurto (Padrão)</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->
          <v-subheader>Mapa</v-subheader>
          <!-- <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Camada de tráfego</v-list-item-title>
                        <v-list-item-subtitle>Habilitar camada de tráfego. Informações retornadas pelo google</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->
          <v-list-item @click="dialog = true">
            <v-list-item-content>
              <v-list-item-title>Tipo de mapa</v-list-item-title>
              <v-list-item-subtitle>Selecione o tipo de mapa (Mapa)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Tipo de rótulo</v-list-item-title>
                        <v-list-item-subtitle>Definir tipo de identificador exibido nos veículos do mapa (Placa)</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item> -->
          <v-list-item>
            <v-list-item-content @click="autoUpdateMap = !autoUpdateMap">
              <v-list-item-title>Atualização automática do mapa</v-list-item-title>
              <v-list-item-subtitle>Habilitar atualização de posiçõs no mapa a cada minuto</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox v-model="autoUpdateMap"></v-checkbox>
            </v-list-item-action>
          </v-list-item>
          <v-subheader>Conta</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Usuário</v-list-item-title>
              <v-list-item-subtitle>{{ usuario.login }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <v-dialog persistent v-model="dialog">
      <v-card>
        <v-card-title>Tipo do mapa</v-card-title>
        <v-radio-group v-model="tipoMapa">
          <v-list style="transform: translateY(-20px)">
            <v-list-item>
              <v-radio label="Satélite (Padrão)" value="satellite"></v-radio>
            </v-list-item>
            <v-list-item>
              <v-radio label="Rua" value="roadmap"></v-radio>
            </v-list-item>
            <v-list-item>
              <v-radio label="Híbrido" value="hybrid"></v-radio>
            </v-list-item>
            <v-list-item>
              <v-radio label="Terreno" value="terrain"></v-radio>
            </v-list-item>
          </v-list>
        </v-radio-group>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="applyTipo">
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';

export default {
  name: 'Config',
  components: {
    Navbar
  },
  data: () => ({
    usuario: {},
    dialog: false,
    tipoMapa: 'satellite',
    autoUpdateMap: false,
    customization: {},
  }),
  methods: {
    applyTipo() {
      localStorage.setItem("mapTypeId", this.tipoMapa)
      this.dialog = false;
    }
  },
  watch: {
    autoUpdateMap() {
      localStorage.setItem("autoUpdateMap", this.autoUpdateMap);
    }
  },
  mounted() {
    this.customization = window.mainApp.customization;

    if (localStorage.getItem("user"))
      this.usuario = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("mapTypeId"))
      this.tipoMapa = localStorage.getItem("mapTypeId")
    /* if (localStorage.getItem("autoUpdateMap"))
      this.autoUpdateMap = localStorage.getItem("autoUpdateMap") == 'true'
    setTimeout(() => {
      window.navbar.setTitle("Configurações");
    }, 100); */
  }
}
</script>

