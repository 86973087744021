<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar /> <!-- scroll-target="#div-content"  -->

    <!-- botões para acessar outras página  -->
    <v-speed-dial v-model="fabMaisOpcoes" :direction="top" transition="slide-x-reverse-transition"
      style="margin-bottom: 10px;" bottom right absolute fixed>
      <template v-slot:activator>
        <v-btn v-model="fabMaisOpcoes" color="black" elevation="6" fab dark>
          <v-icon v-if="fabMaisOpcoes">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="red" router to="/Map">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <!-- <v-btn fab dark small color="purple" router to="/Vehicle">
          <v-icon>mdi-car</v-icon>
        </v-btn> -->
      <!-- <v-btn fab dark small color="green" router to="/Events">
        <v-icon>mdi-calendar-text</v-icon>
      </v-btn> -->
      <v-btn fab dark small color="indigo" router to="/Report">
        <v-icon>mdi-text-box-outline</v-icon>
      </v-btn>
    </v-speed-dial>

    <div id="div-content">
      <v-banner id="divTitle" class="text-h6" shaped>
        Lista de Veículos<br /><br />
        <v-text-field solo clearable v-model="txtFilter" label="Pesquisar placa" append-icon="mdi-magnify"
          @click:append="makeFilter" @click:clear="clearFilter"
          @input="txtFilter = txtFilter.toUpperCase()"></v-text-field>
      </v-banner>

      <div v-if="items.length == 0" style="width: 100%; text-align: center;margin-top: 200px;">
        <span>Não há itens nessa lista</span><br>
      </div>

      <div id="divList">
        <v-list class="list" dense v-if="items.length > 0">
          <v-list-item v-for="(item, i) in items" :key="i" @click="click(item)">
            <div class="list-item">
              <v-row>
                <v-col cols="3" style="padding-right: 0;">
                  <div style="text-align: center;">
                    <v-avatar size="40" color="#757575" style="margin-top: 15px;">
                      <v-icon medium>mdi-car-side</v-icon>
                    </v-avatar><br />
                    <span class="value1">{{ item.placa }}</span><br />
                    <span class="value1">{{ item.velocidade ? item.velocidade : 0 }} km/h</span><br />
                    <v-icon large :style="item.ignicao ? 'color:green;' : 'color:red;'">mdi-key</v-icon>
                  </div>
                </v-col>
                <v-col>
                  <v-list-item-content>
                    <span class="title">{{ item.account.toUpperCase() }}</span>
                    <span><span class="label">Cid:</span>&nbsp;<span class="value">{{ item.cidade }}</span></span>
                    <span><span class="label">End:</span>&nbsp;<span class="value">{{ item.localizacao }}</span></span>
                    <span><span class="label">Mot:</span>&nbsp;<span class="value">{{ item.motorista }}</span></span>
                    <span><span class="label">Frota:</span>&nbsp;<span class="value">{{ item.frota }}</span></span>
                    <span><span class="label">Dt/GPS:</span>&nbsp;<span class="value">{{ item.date }} {{ item.time
                    }}</span></span>
                  </v-list-item-content>
                </v-col>
              </v-row>
            </div>
          </v-list-item>
        </v-list>
      </div>

      <v-alert style="position: fixed; width:100%; bottom: 5px" v-model="alert_atc" type="error"
        transition="scale-transition" elevation="6">
        {{ alert }}
      </v-alert>
      <v-bottom-sheet v-model="dialog" hide-overlay persistent transition="dialog-bottom-transition" elevation="6">
        <v-card v-model="dialog" transition="scroll-y-reverse-transition" :style="`bottom: 0; width: 100%; left: 0;`"
          outlined>
          <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center">
                <div style="float: right"><v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn></div>
                <!-- Informações do {{ getInfo('tipo') }}  -->
                <div class="text-h6">Placa: {{ getInfo('placa') }}</div>
                <!-- <div class="text-subtitle-2 font-weight-light">Última atualização: {{ new
                  Date(getInfo('lastupdate')).toLocaleDateString() }} {{ new
                  Date(getInfo('lastupdate')).toLocaleTimeString() }}
                </div> -->
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Marca:</b> {{ getInfo('marca') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Modelo:</b> {{ getInfo('modelo') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Cor:</b> {{ getInfo('cor') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Frota:</b> {{ getInfo('FROTA') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Ano Modelo:</b> {{ getInfo('anomodelo') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Ano Fabricação:</b> {{ getInfo('anofabricacao') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Chassi:</b> {{ getInfo('chassi') }}
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <b>Renavam:</b> {{ getInfo('renavam') }}
              </v-col>
              <!-- <v-col cols="5" align="center" justify="center">
              <b>Peso:</b> {{ getInfo('peso') }}
            </v-col>
            <v-col cols="5" align="center" justify="center">
              <b>Volume:</b> {{ getInfo('volume') }}
            </v-col> -->
            </v-row>
          </v-container>
        </v-card>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<style lang="scss">
.v-list-item {
  justify-content: center !important;
}

.v-input__control {
  align-items: center !important;
}

.v-input__slot {
  width: auto !important;
}
</style>

<script>
import Navbar from '../components/Navbar';
import api from '@/plugins/api.js';

export default {
  name: 'Vehicles',
  components: {
    Navbar
  },
  data: () => ({
    items: [],
    allItens: [],
    user: {},
    // placa: null,
    // selecionado: null,
    alert_atc: false,
    alert: null,
    dialog: false,
    fabMaisOpcoes: false,
    txtFilter: "",
    customization: {},
  }),
  methods: {
    click(vehicle) {
      // salva o veículo no cache para ser aberto na tela principal do mapa
      localStorage.setItem("placaFromVehiclePage", JSON.stringify(vehicle));
      localStorage.setItem("navigatedFromVehicle", 1);
      this.$router.push("/Map");
    },
    clearFilter() {
      this.getListaVeiculos();
    },
    makeFilter() {
      if (this.txtFilter)
        this.items = this.allItens.filter(i => (i.placa.toLowerCase().includes(this.txtFilter.toLowerCase())));
    },
    getInfo(info) {
      return this.selecionado ? this.selecionado[info] ? this.selecionado[info] : "Não informado" : "Não informado"
    },
    getListaVeiculos() {
      api(localStorage.getItem("token"))
        .get(`endpoint/listaVeiculosBtx/addwhere=idcliente:${this.user.IDCLIENTE}?noPagination=true`)
        .then((r) => {
          if (r.ok) {
            this.allItens = r.data.endpoint;
            this.items = r.data.endpoint;
            /* account, bateria, bloqueio, cidade, date, frota, hodometro, horimetro, idveiculo, ignicao, 
              latitude, localizacao, longitude, marca, modelo, motorista, panico, placa, time, tipo, velocidade */
          }
          window.navbar.loading = false;
        });
    }
  },
  watch: {
    txtFilter() {
      if (this.txtFilter.length > 0) {
        this.makeFilter();
      }
    }
  },
  mounted() {
    window.navbar.loading = true;
    this.customization = window.mainApp.customization;

    if (localStorage.getItem("user"))
      this.user = JSON.parse(localStorage.getItem("user"));

    this.items = []

    this.getListaVeiculos();

    setTimeout(() => {
      window.navbar.setTitle("Veículos");
    }, 100);
  }
}
</script>

