<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar />

    <!-- botões para acessar outras página  -->
    <v-speed-dial v-model="fabMaisOpcoes" :direction="top" transition="slide-x-reverse-transition"
      style="margin-bottom: 10px;" bottom right absolute fixed>
      <template v-slot:activator>
        <v-btn v-model="fabMaisOpcoes" color="black" elevation="6" fab dark>
          <v-icon v-if="fabMaisOpcoes">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="red" router to="/Map">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn fab dark small color="#00897B" router to="/Vehicle">
        <v-icon>mdi-car</v-icon>
      </v-btn>
      <!-- <v-btn fab dark small color="green" router to="/Events">
        <v-icon>mdi-calendar-text</v-icon>
      </v-btn> -->
      <!-- <v-btn fab dark small color="indigo" router to="/Report">
          <v-icon>mdi-text-box-outline</v-icon>
        </v-btn> -->
    </v-speed-dial>

    <v-alert style="position: fixed; width:80vw; top: 70vh; z-index: 200; right: 0px;" v-model="mapType_changed_atc"
      :type="alert_type" transition="scroll-x-reverse-transition" elevation="6">
      {{ msg_alert }}
    </v-alert>

    <div id="div-content">
      <v-banner id="divTitle" class="text-h6" shaped>
        {{ reportTitle }}<br /><br />
        <span style="margin-left: 10px;" v-if="reportPlaca">
          <v-icon v-text="'mdi-car-side'" style="margin-right: 27px;"></v-icon>{{ reportPlaca }}
        </span>
      </v-banner>

      <div v-if="items.length == 0" style="width: 100%; text-align: center;margin-top: 200px;">
        <span>Não há dados para os filtros selecionados</span><br>
        <!-- <v-icon>mdi-magnify</v-icon> -->
      </div>

      <div id="divList">
        <v-list class="list" dense v-if="items.length > 0">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item">
            <div class="list-item">
              <v-row>
                <v-col cols="1">
                  <v-list-item-icon class="pa-0 ma-0">
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                </v-col>
                <v-col>
                  <v-list-item-content class="pa-0 ma-0">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <div ref="divMsgStatusProcess" v-html="item.data"></div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col></v-col>
              </v-row>
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import api from '../plugins/api';

export default {
  name: 'Relatorios',
  components: {
    Navbar
  },
  data: () => ({
    items: [],
    user: {},
    reportTitle: 'Relatório',
    reportPlaca: '',
    fabMaisOpcoes: false,
    mapType_changed_atc: false,
    msg_alert: "",
    alert_type: "success",
    timer_update_map: null,
    customization: {},
  }),
  methods: {
    showAlert(msg, type) {
      this.msg_alert = msg;
      this.alert_type = type;
      this.mapType_changed_atc = true;
      setTimeout(() => {
        this.mapType_changed_atc = false;
      }, 5000);
    },
  },
  mounted() {
    this.customization = window.mainApp.customization;
    if (localStorage.getItem("user")) this.user = JSON.parse(localStorage.getItem("user"));

    setTimeout(() => {
      window.navbar.setTitle("Relatório");
    }, 100);

    window.generateReport = (filtros) => {
      window.navbar.loading = true;

      this.reportTitle = `Relatório de ${filtros.type.text}`;
      this.reportPlaca = filtros.veiculo.placa;
      this.items = [];

      const itemIcon = filtros.type.text == "Posições" ? "mdi-map-marker-radius"
        : filtros.type.text == "Status Ignição" ? "mdi-car-cog" : "mdi-car-cruise-control";

      api(localStorage.getItem("token"))
        .get(`endpoint/${filtros.type.value}/addwhere=idcliente:${this.user.IDCLIENTE},idveiculo:${filtros.veiculo.idveiculo},datainicial:${filtros.datainicial.split("T")[0]},datafinal:${filtros.datafinal.split("T")[0]},horainicial:${filtros.datainicial.split("T")[1]},horafinal:${filtros.datafinal.split("T")[1]},velocidade:${filtros.velocidade}?noPagination=true`)
        .then(r => {
          if (r.ok) {
            try {
              if (Object.keys(r.data.endpoint[0]).length > 0) {
                for (const e in r.data.endpoint[0]) {
                  let auxData = "";
                  for (const key of Object.keys(r.data.endpoint[0][e]))
                    if ((filtros.type.text == "Posições" || filtros.type.text == "Excesso de Velocidade") && key != "Data GPS" && key != "Placa")
                      auxData += `${auxData ? "<br>" : ""}${key}: ${r.data.endpoint[0][e][key]}`
                    else if (filtros.type.text == "Status Ignição" && key != "Placa")
                      auxData += `${auxData ? "<br>" : ""}${key}: ${r.data.endpoint[0][e][key]}`

                  this.items.push(
                    {
                      icon: itemIcon,
                      title: filtros.type.text == "Posições" || filtros.type.text == "Excesso de Velocidade" ? r.data.endpoint[0][e]["Data GPS"] : r.data.endpoint[0][e].Placa,
                      data: auxData
                    });
                }
              } else {
                this.showAlert("Não encontramos dados para os filtros informados", "warning");
                setTimeout(() => {
                  window.navbar.dialog = true;
                }, 5000);
              }
            } catch (e) {
              this.showAlert(`Não encontramos dados para os filtros informados\n${e.message}`, "warning");
              setTimeout(() => {
                window.navbar.dialog = true;
              }, 5000);
            }
          } else {
            this.showAlert("Não encontramos dados para os filtros informados", "warning");
            setTimeout(() => {
              window.navbar.dialog = true;
            }, 5000);
          }

          window.navbar.loading = false;
        });
    }

    // abre o dialogo de filtro assim que entra na tela do relatório
    window.navbar.dialog = true;
  }
}
</script>

