<template>
  <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
    <Navbar />
    <div id="div-content">
      <v-skeleton-loader width="100%" type="image"
        :style="`display: ${loading}; position: fixed; height: 100% !important; z-index: 1000`"></v-skeleton-loader>

      <!-- botões para acesso a outras páginas  -->
      <v-speed-dial v-model="fabMaisOpcoes" :direction="top" transition="slide-x-reverse-transition"
        style="margin-bottom: 10px;" bottom right absolute>
        <template v-slot:activator>
          <v-btn v-model="fabMaisOpcoes" color="#212121" fab dark>
            <v-icon v-if="fabMaisOpcoes">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <v-btn v-if="mapaVehicle" fab dark small color="red" @click="loadFrotaMapa">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn fab dark small color="#00897B" @click="navigateTo('/Vehicle')">
          <v-icon>mdi-car</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="green" @click="navigateTo('/Events')">
          <v-icon>mdi-calendar-text</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click="navigateTo('/Report')">
          <v-icon>mdi-text-box-outline</v-icon> <!-- truck-fast-outline -->
        </v-btn>
      </v-speed-dial>

      <!-- botões para configurações do mapa -->
      <v-speed-dial v-model="fabMapType" :direction="top" transition="slide-x-transition" style="margin-bottom: 10px;"
        bottom left absolute>
        <template v-slot:activator>
          <v-btn v-model="fabMapType" color="#212121" fab dark>
            <v-icon v-if="fabMapType">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-map-check-outline
            </v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="green" @click="setMapTypeId('terrain')">
          <v-icon>mdi-terrain</v-icon>
        </v-btn>
        <v-btn fab dark small color="#00897B" @click="setMapTypeId('hybrid')">
          <v-icon>mdi-map</v-icon>
        </v-btn>
        <v-btn fab dark small color="indigo" @click="setMapTypeId('roadmap')">
          <v-icon>mdi-road</v-icon>
        </v-btn>
        <v-btn fab dark small color="red" @click="setMapTypeId('satellite')">
          <v-icon>mdi-earth</v-icon>
        </v-btn>
        <v-btn fab dark small color="#FBC02D" @click="autoUpdateMapClick">
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-speed-dial>

      <!-- botões de zoom específicos -->
      <div v-if="mapaVehicle || dialog"
        style="margin-top: 130px; margin-left: 20px; z-index: 2; height: auto; width: auto; position: absolute; background-color: #21212160; border-radius: 23px; padding: 10px;">
        <span style="margin-right: 10px;">zoom</span>
        <v-btn color="#212121" width="70" dark rounded small @click="setMapZoom(4)">
          País
        </v-btn>
        <v-btn color="#212121" width="70" dark rounded small @click="setMapZoom(7)">
          Estado
        </v-btn>

        <v-btn color="#212121" width="70" dark rounded small @click="setMapZoom(10)">
          Cidade
        </v-btn>
        <v-btn color="#212121" width="70" dark rounded small @click="setMapZoom(14)">
          Bairro
        </v-btn>
      </div>

      <!-- botões de zoom e localização -->
      <v-btn fab dark small top right absolute color="#212121" @click="getMyLocation"
        style="margin-top: 160px; margin-right: 0; z-index: 2;">
        <v-icon>mdi-crosshairs-gps</v-icon>
      </v-btn>
      <v-btn fab dark small top right absolute color="#212121" @click="setMapZoomPlus"
        style="margin-top: 210px; margin-right: 0; z-index: 2;">
        <v-icon>mdi-magnify-plus</v-icon>
      </v-btn>
      <v-btn fab dark small top right absolute color="#212121" @click="setMapZoomMinus"
        style="margin-top: 260px; margin-right: 0; z-index: 2;">
        <v-icon>mdi-magnify-minus</v-icon>
      </v-btn>

      <!-- alertas -->
      <v-alert style="position: fixed; width:80vw; top: 70vh; z-index: 200; right: 0px;" v-model="mapType_changed_atc"
        :type="alert_type" transition="scroll-x-reverse-transition" elevation="6">
        {{ msg_alert }}
      </v-alert>

      <!-- Div Mapa -->
      <div id="map_div"></div>

      <!-- dados do veículo selecionado -->
      <!-- <div id="windowVehicleData">
        <v-row justify="center">
          <v-col cols="4">
            <div class="btnToggle" @click="btnToggleWindow"><v-icon dark>mdi-menu-up</v-icon></div>
          </v-col>
        </v-row>
        <v-row class="rowBody">
          <v-row>
            <v-col>
              <div class="text-h6 text-center">Placa: {{ getInfo('placa') }}</div>
            </v-col>
          </v-row>
        </v-row>
      </div> -->

      <v-bottom-sheet v-model="showMenu" hide-overlay persistent transition="dialog-bottom-transition">
        <v-card v-model="showMenu" transition="scroll-y-reverse-transition" :style="`bottom: 0; width: 100%; left: 0;`"
          outlined>
          <v-card-title>Escolha um veiculo</v-card-title>
          <v-container fill-height>
            <v-list>
              <v-list-item v-for="(   item, index   ) in    menuItems   " :key="index" @click="selectCar(item)">
                <v-list-item-title>{{ item.placa }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-container>
        </v-card>
      </v-bottom-sheet>

      <v-bottom-sheet v-model="dialog" width="auto" hide-overlay persistent transition="dialog-bottom-transition">
        <v-card v-model="dialog" transition="scroll-y-reverse-transition" :style="`bottom: 0; width: 100%; left: 0;`"
          outlined>
          <v-container fluid fill-height>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center">
                <div style="float: right"><v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn></div>
                <!-- Informações do {{ getInfo('tipo') }} -->
                <div class="text-h6">Placa: {{ getInfo('placa') }}</div>
                <div class="text-body font-weight-regular">Localização: {{ getInfo('localizacao') }}</div>
                <!-- <div class="text-subtitle-2 font-weight-light">Última atualização: {{ getInfo('date') }} {{
                  getInfo('time') }}
                </div> -->
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-speedometer</v-icon> <span style="text-subtitle-1">{{ getInfo('velocidade') }}
                        Km/h</span>
                    </div>
                  </template>
                  <span>Velocidade</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-power</v-icon> <span style="text-subtitle-1">{{ getInfo('ignicao') == 1 ? 'Ligada' :
                        'Desligada' }}</span>
                    </div>
                  </template>
                  <span>Ignição</span>
                </v-tooltip>
              </v-col>
              <!-- <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-clock-time-eight</v-icon> <span style="text-subtitle-1">{{ getInfo('horimetro') }}
                        h</span>
                    </div>
                  </template>
                  <span>Horímetro</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-gauge</v-icon> <span style="text-subtitle-1">{{ getInfo('hodometro') }} Km</span>
                    </div>
                  </template>
                  <span>Hodômetro</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-alert</v-icon> <span style="text-subtitle-1">{{ getInfo('alerta') == 1 ? 'Ativo' :
                        'Inativo' }}</span>
                    </div>
                  </template>
                  <span>Alerta</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-car-battery</v-icon> <span style="text-subtitle-1">{{ getInfo('bateria') }} V</span>
                    </div>
                  </template>
                  <span>Bateria</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-lock</v-icon> <span style="text-subtitle-1">{{ getInfo('bloqueio') == 1 ? 'Ativo' :
                        'Inativo' }}</span>
                    </div>
                  </template>
                  <span>Bloqueio</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" align="center" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div text v-bind="attrs" color="black" v-on="on">
                      <v-icon>mdi-directions-fork</v-icon> <span style="text-subtitle-1">{{ getInfo('direcao') }}</span>
                    </div>
                  </template>
                  <span>Direção</span>
                </v-tooltip>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<style lang="scss">
$bkgWindowColor: #212121;
$windowHeight: 300px;
$btxToggleWindowHeight: 40px;

#map_div,
.Map {
  width: 100%;
  height: 100vh;
  /* calc(100vh - 65px) */
  overflow: hidden;
  position: block !important
}

.v-skeleton-loader div {
  height: 100%;
  color: #808080;
}

#windowVehicleData {
  /* background-color: blueviolet; */
  position: absolute;
  height: $windowHeight;
  width: calc(100vw - 40px);
  z-index: 10;
  top: calc(100vh - 40px);
  left: 20px;
  elevation: 6;

  .btnToggle {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: $btxToggleWindowHeight;
    text-align: center;
    background-color: $bkgWindowColor;

    .v-icon {
      margin-top: -5px !important;
    }
  }

  .rowBody {
    margin: 0 !important;
    border-radius: 10px;
    background-color: $bkgWindowColor;
  }

  .show-window {
    animation: slidWindowCarData 800ms alternate ease-in-out;
  }

  @keyframes slidWindowCarData {
    to {
      transform: translate(0px, -300px);
      /* -calc($windowHeight - $btxToggleWindowHeight) */
    }
  }
}
</style>

<script>
import Navbar from '../components/Navbar';
import api from '../plugins/api';

let cluster = null;

const ControlPosition =
{
  TOP_LEFT: 1,
  TOP_CENTER: 2,
  TOP: 2,
  TOP_RIGHT: 3,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  RIGHT_TOP: 7,
  RIGHT: 7,
  RIGHT_CENTER: 8,
  RIGHT_BOTTOM: 9,
  BOTTOM_LEFT: 10,
  BOTTOM_CENTER: 11,
  BOTTOM: 11,
  BOTTOM_RIGHT: 12,
  CENTER: 13
}

export default {
  name: 'Map',
  components: {
    Navbar
  },
  data: () => ({
    user: {},
    loading: "block",
    markers: {},
    markersOnMap: [],
    allMarkers: [],
    showMarker: null,
    dialog: false,
    menuItems: [],
    showMenu: false,
    fabMapType: false,
    fabMaisOpcoes: false,
    mapType_changed_atc: false,
    msg_alert: "",
    alert_type: "success",
    timer_update_map: null,
    latLngCentroBrasil: { lat: -15.885281, lng: -52.273029 }, // barra do graças MS, centro do Brasil -15.885281, -52.273029
    panorama: null,
    showWindow: false,
    mapaVehicle: false,
    customization: {},
  }),
  methods: {
    navigateTo(path) {
      localStorage.setItem("navigatedFromVehicle", 0);
      this.$router.push(path);
    },
    initMap() {
      // eslint-disable-next-line no-undef
      window.map = new google.maps.Map(
        document.getElementById('map_div'),
        {
          minZoom: 2,
          zoom: 4,
          mapTypeId: localStorage.getItem("mapTypeId") ? localStorage.getItem("mapTypeId") : 'terrain',
          center: this.latLngCentroBrasil,
          disableDefaultUI: true,
          streetViewControl: true,
          streetViewControlOptions: {
            position: ControlPosition.LEFT_CENTER // google.maps.ControlPosition.LEFT_CENTER
          },
          gestureHandling: "greedy"
          // fullscreenControl: true
        });

      this.panorama = window.map.getStreetView();
      this.panorama.set

      // this.loadMap();
    },
    toggleStreetView() {
      const toggle = this.panorama.getVisible();

      if (toggle == false) {
        this.panorama.setVisible(true);
        // this.panorama.setPosition(astorPlace);
      }
      else {
        this.panorama.setVisible(false);
      }
    },
    btnToggleWindow() {
      this.showWindow = !this.showWindow;
      console.log(" --> this.showWindow: ", this.showWindow);
      this.showWindow ? document.getElementById('windowVehicleData').className = "show-window" :
        document.getElementById('windowVehicleData').className = "";
    },
    tryInit() {
      const vue = this;
      setTimeout(() => {
        if (window.mainApp.isGoogleMapsLoaded)
          vue.initMap();
        else
          vue.tryInit();
      }, 100);
    },
    getMyLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          window.map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          window.map.setZoom(18);
        },
        (error) => { console.log(` --> code: ${error.code} | message: ${error.message}`) },
        { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true });
    },
    setMapTypeId(type) {
      localStorage.setItem("mapTypeId", type);
      window.map.setMapTypeId(type);

      const msg = "modo " + (type == "roadmap" ? "rua" : type == "satellite" ? "satelite" : type == "hybrid" ? "hibrido" : "terreno") + " ativado";
      this.showAlert(msg, "success");
    },
    setMapZoomPlus() {
      window.map.setZoom(window.map.getZoom() + 1);
    },
    setMapZoomMinus() {
      window.map.setZoom(window.map.getZoom() - 1);
    },
    setMapZoom(zoom) {
      window.map.setZoom(zoom);
    },
    showAlert(msg, type) {
      this.msg_alert = msg;
      this.alert_type = type;
      this.mapType_changed_atc = true;
      setTimeout(() => {
        this.mapType_changed_atc = false;
      }, 5000);
    },
    loadMap() {
      this.markers = {}
      api(localStorage.getItem("token"))
        .get(`endpoint/mapa_btx/addwhere=idcliente:${this.user.IDCLIENTE}?noPagination=true`) ////this.user.IDCLIENTE}
        .then(r => {
          if (r.ok) {
            this.allMarkers = r.data.endpoint;
            r.data.endpoint.forEach(e => {
              if (e) {
                if (e.latitude != null && e.longitude != null) {
                  const mrks = this.getByLocation(e.latitude, e.longitude)
                  if (this.getByLocation2(e.latitude, e.longitude).length < 1) {
                    if (!this.markers[e.idveiculo]) {
                      // eslint-disable-next-line no-undef
                      this.markers[e.idveiculo] = {
                        label: mrks.length > 1 ? mrks.map(m => m.placa).join("\n") : mrks[0].placa,
                        position: {
                          lat: e.latitude,
                          lng: e.longitude
                        },
                        info: e,
                        // eslint-disable-next-line no-undef
                        animation: google.maps.Animation.DROP,
                        icon: {
                          url: e.icone_tipo ? new Buffer.from(e.icone_tipo.data).toString() :
                            <v-img src="http://vld.brasiltrack.com/logistica/images/pin-btxapp.png" alt=""
                              contain
                              height="150px"
                              wight="150px"></v-img>,
                          // eslint-disable-next-line no-undef
                          scaledSize: new google.maps.Size(150, 150), // scaled size
                          // eslint-disable-next-line no-undef
                          origin: new google.maps.Point(0, 30), // origin
                          // eslint-disable-next-line no-undef
                          anchor: new google.maps.Point(25, 25) // anchor
                        }
                      };
                    } else
                      this.markers[e.idveiculo].position = {
                        lat: e.latitude,
                        lng: e.longitude
                      };
                  }
                }
              }
            });
            this.createMarkers();
            this.loading = "none";
          }
        });
      setTimeout(() => {
        window.navbar.setTitle("Mapa | Frota");
      }, 1000);

      window.navbar.viewBtnMenuFrota = false;
      localStorage.setItem("viewBtnMenuFrota", window.navbar.viewBtnMenuFrota);
    },
    createMarkers() {
      if (cluster != null) {
        cluster.clearMarkers();
      }

      const locations = Object.keys(this.markers).map(m => {
        return this.markers[m];
      });

      var markers = locations.map((location) => {
        // eslint-disable-next-line no-undef
        return new google.maps.Marker(location);
      });

      this.markersOnMap = markers
      const vue = this;
      markers.forEach((marker) => {
        marker.addListener('click', (r) => {
          window.map.setCenter(r.latLng);
          const mrks = this.getByLocation(r.latLng.lat(), r.latLng.lng())
          console.log(" --> createMarkers mrks: ", mrks);
          if (mrks.length > 1) {
            vue.menuItems = mrks;
            vue.showMenu = true;
          } else
            this.selectCar(mrks[0])
        });
      });

      // eslint-disable-next-line no-undef
      cluster = new MarkerClusterer(window.map, markers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 18 });
    },
    getInfo(info) {
      return this.showMarker ? this.markers[this.showMarker].info[info] : "";
    },
    getByLocation(lat, lng) {
      return Object.keys(this.allMarkers)
        .filter(m => (this.allMarkers[m].latitude == lat && this.allMarkers[m].longitude == lng))
        .map(m => this.allMarkers[m])
    },
    getByPlaca(placa) {
      return this.allMarkers.filter(m => (m.placa == placa));
    },
    getByLocation2(lat, lng) {
      return Object.keys(this.markers)
        .filter(m => (this.markers[m].latitude == lat && this.markers[m].longitude == lng))
        .map(m => this.markers[m])
    },
    selectCar(car) {
      this.menuItems = [];
      this.showMenu = false;

      this.showMarker = null;
      setTimeout(() => {
        this.showMarker = car.idveiculo
      }, 50)
    },
    autoUpdateMapClick() {
      window.autoUpdateMap = !window.autoUpdateMap;
      localStorage.setItem("autoUpdateMap", window.autoUpdateMap);

      const msg = "modo atualização automática " + (window.autoUpdateMap ? "ativada. 30seg" : "desativada");
      this.showAlert(msg, (window.autoUpdateMap ? "success" : "warning"));

      this.createTimerUpdMap();
    },
    createTimerUpdMap() {
      if (window.autoUpdateMap) {
        window.timer_update_map = setInterval(() => {
          if (!window.autoUpdateMap)
            clearInterval(window.timer_update_map);
          else if (this.$route.name == "Map")
            this.loadMap();
        }, 30000);
      } else
        clearInterval(window.timer_update_map);
    },
    loadFrotaMapa() {
      window.map.setCenter(this.latLngCentroBrasil);
      this.setMapZoom(4);
      this.loadMap();
      window.autoUpdateMap = localStorage.getItem("autoUpdateMap") == 'true';
      this.mapaVehicle = false;
      this.showMarker = null;
    }
  },
  watch: {
    showMarker() {
      this.dialog = false;
      if (this.showMarker)
        setTimeout(() => {
          this.dialog = true
        }, 50);
    },
  },
  mounted() {
    this.customization = window.mainApp.customization;

    const vue = this;
    this.tryInit();

    if (localStorage.getItem("user")) this.user = JSON.parse(localStorage.getItem("user"));

    window.loadFrotaMapa = () => {
      window.map.setCenter(vue.latLngCentroBrasil);
      vue.setMapZoom(4);
      vue.loadMap();
      window.autoUpdateMap = localStorage.getItem("autoUpdateMap") == 'true';
      vue.mapaVehicle = false;
      this.showMarker = null;
    }

    // carrega veículo selecionado
    if (localStorage.getItem("placaFromVehiclePage") && localStorage.getItem("navigatedFromVehicle") == 1) {
      localStorage.setItem("navigatedFromVehicle", 0);

      window.autoUpdateMap = false;
      clearInterval(window.timer_update_map);

      this.vehicleData = false;

      this.menuItems = [];
      this.showMenu = false;
      this.markers = {};

      setTimeout(() => {
        var vehicleData = JSON.parse(localStorage.getItem("placaFromVehiclePage"));
        this.allMarkers = [];
        this.allMarkers.push(vehicleData);

        this.markers[vehicleData.idveiculo] = {
          title: vehicleData.placa,
          label: vehicleData.placa,
          position: {
            lat: vehicleData.latitude,
            lng: vehicleData.longitude
          },
          map: window.map,
          info: vehicleData,
          // eslint-disable-next-line no-undef
          animation: google.maps.Animation.DROP,
          icon: {
            url: vehicleData.icone_tipo ? new Buffer.from(vehicleData.icone_tipo.data).toString() :
              <v-img src="http://vld.brasiltrack.com/logistica/images/pin-btxapp.png" alt=""
                contain height="150px" wight="150px"></v-img>,
            // eslint-disable-next-line no-undef
            scaledSize: new google.maps.Size(150, 150), // scaled size
            // eslint-disable-next-line no-undef
            origin: new google.maps.Point(0, 30), // origin
            // eslint-disable-next-line no-undef
            anchor: new google.maps.Point(25, 25) // anchor
          }
        };

        this.createMarkers();

        setTimeout(() => {
          window.navbar.setTitle("Mapa | Veículo");
        }, 1000);

        this.selectCar(vehicleData);
        window.map.setCenter({
          lat: vehicleData.latitude,
          lng: vehicleData.longitude,
        });
        window.map.setZoom(18);

        this.mapaVehicle = true;
        window.navbar.viewBtnMenuFrota = true;
        localStorage.setItem("viewBtnMenuFrota", window.navbar.viewBtnMenuFrota);

        this.loading = "none";
      }, 1000);
    } else {
      this.loadMap();

      if (localStorage.getItem("autoUpdateMap")) {
        window.autoUpdateMap = localStorage.getItem("autoUpdateMap") == 'true';
        this.createTimerUpdMap();
      }
    }
  }
}
</script>

